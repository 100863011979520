import React from 'react'
import Slider from 'react-slick'

import './slider.scss'
import Button from '../button'

const HeroSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
  }

  const getStyle = (slideFileName) => {
    const style = {
      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7) 100%, rgba(255, 255, 255, 0.1) 30%), url(/images/hero-sliders/${slideFileName})`,
      backgroundPosition: 'center',
    }
    return style
  }

  return (
    <Slider {...settings}>
      <div className='hero-slide'>
        <div className='hero-slide-content'>
          <h1>
            <div>Whether it’s large construction sites, modern offices, critical DataCenters, or cozy homes.</div>
          </h1>
          <h5 className='hero-subtitle'>At Hygienz, we are dedicated to transforming and revitalizing every space.</h5>
          <Button flashy to='/free-quote'>Get a Free Quote</Button>
        </div>
        <div className='slide-img' style={getStyle('slider-1.jpg')} />
      </div>
      <div className='hero-slide'>
        <div className='hero-slide-content'>
          <h1>
            END OF TENANCY CLEANING
          </h1>
          <h5 className='hero-subtitle'>
            <div>Moving soon? Make sure to secure your deposit with a top-quality clean.</div>
          </h5>
          <Button flashy to='/free-quote'>Get a Free Quote</Button>
        </div>
        <div className='slide-img align-img-left' style={getStyle('slider-2.jpg')} />
      </div>
      <div className='hero-slide'>
        <div className='hero-slide-content'>
          <h1>
            The Laser Cleaning Solution for Commercial and Industrial Environments
          </h1>
          <h5 className='hero-subtitle'>
            <div>
              <div>Laser Cleaning: Precision, efficiency, and technology in every beam.</div>
            </div>
          </h5>
          <Button flashy to='/free-quote'>Get a Free Quote</Button>
        </div>
        <div className='slide-img align-img-left' style={getStyle('slider-4.jpg')} />
      </div>
      <div className='hero-slide'>
        <div className='hero-slide-content'>
          <h1>
            Carpet Cleaning Services
          </h1>
          <h5 className='hero-subtitle'>
            <div>
              <div>Carpets are a key investment for your home or office.</div>
              <div>With our professional cleaning service in Auckland, we ensure that your carpets remain in perfect condition, preserving their color and quality over time.</div>
              <div>Protect your investment and enjoy an always immaculate environment with our specialized services.</div>
            </div>
          </h5>
          <Button flashy to='/free-quote'>Get a Free Quote</Button>
        </div>
        <div className='slide-img align-img-left' style={getStyle('slider-3.jpg')} />
      </div>
    </Slider>
  )
}

export default HeroSlider
